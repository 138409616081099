import "./modules/script";
import "./modules/map/mapalex";

import AOS from "aos";
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

onElementHeightChange(document.body, function () {
  AOS.refresh();
  ScrollTrigger.refresh();
});

function onElementHeightChange(elm, callback) {
  var lastHeight = elm.clientHeight;
  var newHeight;

  (function run() {
    newHeight = elm.clientHeight;
    if (lastHeight !== newHeight) callback();
    lastHeight = newHeight;

    if (elm.onElementHeightChangeTimer) {
      clearTimeout(elm.onElementHeightChangeTimer);
    }

    elm.onElementHeightChangeTimer = setTimeout(run, 150);
  })();
}

gsap.config({ trialWarn: false });
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

// import Swiper, { Navigation, Pagination } from 'swiper';
// if($('body').hasClass('home')){
//   const swiper = new Swiper(".home_swipe", {
//     loop: true,
//     spaceBetween: 0,
//     grabCursor: true,
//     slidesPerView:1,
//     speed: 1000,
//     centeredSlides: true,
//     navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   pagination: {
//     el: ".swiper-pagination",
//   },
//   });
//
//   swiper.on('slideChange', function () {
//     gsap.to(swiper.slides[swiper.activeIndex], {scale:1, opacity:1})
//     gsap.to(swiper.slides[swiper.previousIndex], {opacity:0.3, scale:0.8})
//     swiper.slides[swiper.previousIndex].animation.pause(0)
//     swiper.slides[swiper.activeIndex].animation.restart()
//   });
//
//   swiper.slides.forEach((slide, index)=>{
//     let letter = slide.querySelector("h1")
//     let description = slide.querySelector("span")
//     let chars = SplitText.create(description, {type:"chars"})
//     let tl = gsap.timeline({paused:true})
//     tl.from(letter, {scale:0, opacity:0, ease:"back", duration:1})
//       .from(chars.chars, {opacity:0, yPercent:50, stagger:0.02}, "-=0.5")
//     slide.animation = tl
//   })
//
//   swiper.slides[1].animation.play();
// }

/*End lannding animation*/

// ------------------------------------------- //
// Smooth scroller
let smoother = ScrollSmoother.create({
  wrapper: "#smooth-wrapper",
  content: "#smooth-content",
  smooth: 2,
  smoothTouch: 0.1,
  effects: true,
});

smoother.effects(".image_parallax img", { speed: "auto" });

//Heading text animate based on the words count

const sections = gsap.utils.toArray(".animated-heading");
sections.forEach((section, index) => {
  const heading = section.querySelector("h1, h2, h3");

  const wordCount = heading.innerText.split(/\s+/).length;

  let splitType = wordCount > 2 ? "words" : "chars";
  let split = SplitText.create(heading, { type: splitType });
  let animation = gsap.fromTo(
    splitType === "words" ? split.words : split.chars,
    {
      y: 50,
      x: -20,
      opacity: 0,
    },
    {
      y: 0,
      x: 0,
      opacity: 1,
      stagger: 0.1,
      duration: 1,
      ease: "power4.out",
    }
  );

  ScrollTrigger.create({
    trigger: section,
    // start: "top center",
    toggleActions: "play none none pause",
    animation: animation,
    markers: false,
    pinSpacing: false,
  });
});
