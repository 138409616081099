/*scroll header*/
$(window).scroll(function () {
  if ($(document).scrollTop() > 700) {
    $(".header_wrapper").addClass("scrolled");
  } else {
    $(".header_wrapper").removeClass("scrolled");
  }
});


/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function (event) {
  var st = $(this).scrollTop();
  if (st > lastScrollTop) {
    // downscroll code
    $(".header_wrapper").addClass("down");
  } else {
    // upscroll code
    $(".header_wrapper").removeClass("down");
  }
  lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on("click", function () {
  return false;
});

/*Open Menu*/
$(".menu_button").on("click", function () {
  if ($(this).hasClass("is-active")) {
    $("body").css("overflow-y", "scroll");
    $(this).removeClass("is-active");
    $(".menu_button").removeClass("is-active");
    $(".headermenu").removeClass("is-active");
    $(".header_wrapper").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $(".menu_button").addClass("is-active");
    jQuery("body").css("overflow-y", "hidden");
    $(".headermenu").addClass("is-active");
    $(".header_wrapper").addClass("is-active");
  }
});



// Calculate the header height
  const header = document.querySelector(".site-header");
  const headerHeight = header.offsetHeight;
  document.body.style.setProperty("--header-h", `${headerHeight}px`);



/*Gallery*/
import Swiper, { Navigation, Pagination } from "swiper";

// Homepage features Swiper
const home_swi = new Swiper(".homepage_swipe", {
  loop: true,
  spaceBetween: 20,
  grabCursor: true,
  slidesPerView: 1,
  speed: 1000,
  centeredSlides: true,

  navigation: {
    nextEl: '.slider_swiper-button-next',
    prevEl: '.slider_swiper-button-prev',
  },
});


// Homepage rooms Swiper
const rooms_swi = new Swiper(".rooms-swiper", {
  loop: true,
  grabCursor: true,
  slidesPerView: 1,
  speed: 1000,
  centeredSlides: true,

  navigation: {
    nextEl: '.slider_swiper-button-next',
    prevEl: '.slider_swiper-button-prev',
  },

  breakpoints: {
    0: {
      slidesPerView: 1,
    },

    768: {
      slidesPerView: 1,
    },

    1025: {
      slidesPerView: 1,
    },

    1280: {
      slidesPerView: 1,
    },
  }
});

//Video
// if (document.body.classList.contains('home') ) {
window.onload = function() {

	const video = document.getElementById("video");
	const playButton = document.getElementById("play-pause");

	// Event listener for the play/pause button
	playButton.addEventListener("click", function() {
		if (video.paused == true) {
			// Play the video
			video.play();
		} else {
			// Pause the video
			video.pause();
		}
	});
}
// }


// Homepage experience Swiper
const experience_swi = new Swiper(".experience-swiper", {
  loop: true,
  spaceBetween: 60,
  grabCursor: true,
  slidesPerView: 2.7,
  speed: 1000,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  },

  breakpoints: {
    0: {
      slidesPerView: 1,
    },

    768: {
      slidesPerView: 1.5,
    },

    1025: {
      slidesPerView: 2,
    },

    1280: {
      slidesPerView: 2.7,
    },
  }
});


// Homepage testimonials Swiper
const testimonials_swi = new Swiper(".testimonials-swiper", {
  loop: true,
  grabCursor: true,
  slidesPerView: 1,
  speed: 1000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false
  },

  navigation: {
    nextEl: '.slider_swiper-button-next',
    prevEl: '.slider_swiper-button-prev',
  },
});


// Single Room's Page Slider
const gallery_swi = new Swiper(".slider_swipe", {
  loop: true,
  spaceBetween: 20,
  grabCursor: true,
  slidesPerView: 1,
  speed: 1000,
  centeredSlides: true,

  navigation: {
    nextEl: '.slider_swiper-button-next',
    prevEl: '.slider_swiper-button-prev',
  },
});


//Footer Temrature 

document.addEventListener('DOMContentLoaded', () => {
  const tempDiv = document.getElementById('temp');
  //Chania
  const latitude = "35.5112"; 
  const longitude = "24.0292";

  const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m`;

  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      const { current } = data;
      const temperature = current.temperature_2m;
      const weatherContent = `${Math.round(temperature)}&deg`;

      tempDiv.innerHTML = weatherContent;
    })
    .catch(error => {
      console.log('Error fetching weather data:', error);
    });
});


// footer time
const now = new Date();
const hours = now.getHours();
const minutes = now.getMinutes();
const timeDiv = document.getElementById('time');

if (timeDiv) {
    timeDiv.innerHTML = hours + '.' + (minutes < 10 ? '0' : '') + minutes;
}